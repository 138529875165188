<template>
    <div class="brand-list" v-loading="loading">
        <div class="banner-wrap">
            <el-carousel height="300px" arrow="hover" v-loading="loading" v-if="adList.length>0">
                <el-carousel-item v-for="item in adList" :key="item.adv_id">
                    <el-image :src="$img(item.adv_image)" fit="cover"
                              @click="$router.pushToTab(item.adv_url.url)" />
                </el-carousel-item>
            </el-carousel>
            <img v-else src="@/assets/images/nationwide/news_banner.jpg"/>
        </div>
        <el-breadcrumb separator="/" class="crumbs">
            <el-breadcrumb-item :to="{ path: '/' }" class="path-home"><i class="n el-icon-s-home"></i>首页</el-breadcrumb-item>
            <el-breadcrumb-item class="path-help">新闻资讯</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="brand-content">
            <ul class="category-bar" v-if="newsCategoryList.length>0">
                <li :class="currentCategoryId == 0? 'bar-item actived': 'bar-item'" @click="changeNewsCategory('0')">全部动态</li>
                <li
                        v-for="(item, index) in newsCategoryList"
                        :key="index"
                        :class="currentCategoryId == item.id? 'bar-item actived': 'bar-item'"
                        @click="changeNewsCategory(item.id)"
                >{{item.title}}</li>
            </ul>
            <div class="list-wrap">
                <div class="news-list" v-if="newsList.length>0">
                    <div class="news-info" v-for="(item, index) in newsList" :key="index">
                            <div class="news-img">
                                <a @click="viewDetail(item.id)" class="pic" target="_blank">
                                    <img :src="item.thumbnail.path"
                                         onerror="" alt="培训新型职业农民 助推精准扶贫" border="0">
                                </a>
                            </div>
                            <div class="news-text">
                                <div class="tit"><a @click="viewDetail(item.id)" target="_blank">{{ item.title }}</a></div>
                                <div class="des">
                                    <p class="intro">{{ item.abstract }}</p>
                                </div>
                                <div class="more">
                                    <span>{{ item.create_time}}</span>
                                    <a @click="viewDetail(item.id)" target="_blank">详情>></a>
                                </div>
                            </div>
                    </div>
                    <div class="pager">
                        <el-pagination background
                                :pager-count="5"
                                :total="total"
                                prev-text="上一页"
                                next-text="下一页"
                                :current-page.sync="currentPage"
                                :page-size.sync="pageSize"
                                @size-change="handlePageSizeChange"
                                @current-change="handleCurrentPageChange"
                                hide-on-single-page
                        ></el-pagination>
                    </div>
                </div>
                <div class="empty" v-else>
                    <div class="ns-text-align">抱歉，暂无数据！</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {adList} from "@/api/website";
    import { articleClassifyList , articleList} from "@/api/cms/article";

    export default {
        name: "index",
        data: () => {
            return {
                loading: false,
                total: 0,
                currentPage: 1,
                pageSize: 10,
                newsList : [],
                newsCategoryList:[],
                currentCategoryId: 0,
                adList:[],
            }
        },
        created() {
            this.getAdList()
            this.getNewsCategoryList()
            this.getNewsList(0)
        },
        methods: {
            getNewsCategoryList() {
                articleClassifyList({
                    level:'1',
                    parent_id:'0'
                }).then(res => {
                    if (res.code == 0 && res.data) {
                        this.newsCategoryList = res.data;
                    }
                    this.loading = false;
                })
                .catch(err => {
                    this.loading = false;
                    this.$message.error(err.message);
                });
            },
            changeNewsCategory(id){
                this.currentCategoryId = id;
                this.getNewsList(id)
            },
            getNewsList(id) {
                articleList({
                    category_id: id,
                    page: this.currentPage,
                    page_size: this.pageSize,
                }).then(res => {
                    if (res.code == 0 && res.data) {
                        this.total = parseInt(res.data.count)
                        this.newsList = res.data.list;
                    } else {
                        this.$router.push({ path: "/promotion/newsInformation" });
                    }
                })
                .catch(err => {
                    this.$message.error(err.message);
                });
            },
            viewDetail(id) {
                this.$router.push({ path: "/promotion/newsInformation/detail-" + id });

            },
            getAdList() {
                adList({
                    keyword: "NS_PC_NEWS"
                })
                    .then(res => {
                        this.adList = res.data.adv_list;
                        for (let i = 0; i < this.adList.length; i++) {
                            if (this.adList[i].adv_url)
                                this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                    })
            },
            handlePageSizeChange(size) {
                this.pageSize = size
                this.currentPage = 1
                this.getNewsList(this.currentCategoryId)
            },
            handleCurrentPageChange(page) {
                this.currentPage = page
                this.getNewsList(this.currentCategoryId)
            },
        }
    }
</script>

<style lang="scss">
    .el-carousel__arrow--left{left: 150px}
    .el-carousel__arrow--right{right: 150px}
    .banner-wrap{
        width:100%;
        height:300px;
        margin:0;
        padding:0;
        position: relative;
        img{
            height:300px;
            width: 100%;
        }
    }
    .crumbs{
        width: 1210px;
        margin: 30px auto;
        position: relative;
        padding: 0;
    }
    .brand-list{
        width: 100%;
        position: relative;

        .brand-content{
            background-color: #fff;
            width: 1130px;
            border-radius: 8px;
            margin: 0 auto;
            position: relative;
            padding: 40px;
        }
        .list-wrap{
            min-height: 500px;
            .empty{
                margin: 100px auto;
            }
            .pager{
                margin: 40px auto;
                .el-pagination{
                    text-align: center;
                    .el-pagination__rightwrapper{
                        float: none;
                        display: inline-block;
                        margin-right: 20px;
                    }
                }
                .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next {
                    padding: 0 15px;
                }
            }
        }
        .category-bar{
            padding: 0;
            margin: 20px auto 30px;
            position: relative;
            width: 60%;
            display: flex;
            border: 2px solid $base-color;
            .bar-item{
                width: 20%;
                padding: 0;
                border-left: 2px solid $base-color;
                line-height: 40px;
                font-size: 16px;
                color: #999;
                text-align: center;
                cursor: pointer;
                margin-top: 1px;
            }
            .bar-item:first-child{border-left: 0;}
            .bar-item:hover{color:$base-color;}
            .bar-item.actived{
                background-color: $base-color;
                color:#fff;
            }
        }
    }

    .news-list{
        position: relative;
        width: 100%;
        height: 100%;
        .news-info{
            position: relative;
            width: 1130px;
            height: 168px;
            border-bottom: 1px solid #D8D8D8;;
            clear: both;
            padding: 25px 0px;

            .news-img{
                width: 330px;
                height: 168px;
                overflow: hidden;
                text-align: center;
                line-height: 148px;
                margin-right: 40px;
                float: left;
                img{
                    width: 330px;
                    height: 168px;
                }
            }
            .news-text{
                float: left;
                width: 760px;
                position: relative;
                .tit{
                    height: 25px;
                    font-size: 23px;
                    line-height: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-bottom: 20px;
                    a{
                        color: #484848;
                        cursor: pointer;
                    }
                    a:hover{
                        color:$base-color;
                    }
                }
                .des {
                    height: 90px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #737373;
                    font-size: 15px;
                    line-height: 28px;
                }
                .more {
                    text-align: right;
                    overflow: hidden;
                    color: #737373;
                    span{margin-right: 25px;}
                    a{
                        color: #737373;
                        cursor: pointer;
                    }
                }
            }

        }
    }
</style>